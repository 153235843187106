@import "media.css";
@import "colors.css";

@define-mixin typePrimary {
  font-family: "Spezia Medium";
  font-weight: 500;
}

@define-mixin typePrimaryNew {
  font-family: "Spezia Serif Italic";
  font-weight: 400;
}

@define-mixin typeSecondary {
  font-family: "Spezia Regular";
  font-style: normal;
  font-weight: 400;
}

@define-mixin typeSecondaryNew {
  font-family: "Spezia";
  font-weight: 400;
}

@define-mixin typeTertiary {
  font-family: "Spezia Medium";
  font-style: normal;
  font-weight: 700;
}

@define-mixin typeVanity {
  font-family: "Spezia Bold";
  font-weight: 700;
}

@define-mixin typeSerifLight {
  font-family: "Spezia Serif";
  font-weight: 100;
}

@define-mixin typeSerifBold {
  font-family: "Spezia Serif";
  font-weight: 900;
}

@define-mixin typeSansNarrowBold {
  font-family: "Spezia Narrow Bold";
  font-weight: 900;
}

@define-mixin typeSpeziaSerif {
  font-family: "Spezia Serif";
  font-weight: 400;
  font-style: normal;
}

@define-mixin typeSpeziaNarrow {
  font-family: "Spezia Narrow";
  font-weight: 700;
}

@define-mixin typeSpeziaSemiMonoMedium {
  font-family: "Spezia SemiMono Medium";
  font-weight: 500;
}

/* semantic naming*/
@define-mixin eyebrow {
  @mixin typeSpeziaSemiMonoMedium;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
}

@define-mixin linkText {
  @mixin typeSecondary;
  @mixin text_primary;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 1.5;
}

@define-mixin labelText {
  @mixin typeSecondary;

  line-height: 1.5;
  &.labelFocus {
    font-size: 11px;
    padding-top: 8px;
  }
}

@define-mixin captionDescriptionText {
  @mixin typeSecondary;
  font-size: 13px;
  line-height: 100%;
}

@define-mixin captionTitleText {
  @mixin typePrimary;
  font-size: 11px;
  line-height: 1.5;
  letter-spacing: -0.45px;
}

@define-mixin badgeTitleText {
  @mixin typeVanity;
  font-size: 11px;
  line-height: 100%;
  letter-spacing: 0.2px;
}

@define-mixin metaDescriptionText {
  @mixin typeSecondary;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: -0.12px;
}

@define-mixin metaTitleText {
  @mixin typePrimary;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: -0.12px;
}

@define-mixin bodyTitleText {
  @mixin typePrimary;
  font-size: 15px;
  line-height: 1.5;

  @media (--smartphone) {
    font-size: 16px;
    line-height: 1.5;
  }
}

@define-mixin bodyText {
  @mixin typeSecondary;
  font-size: 15px;
  line-height: 1.5;

  @media (--smartphone) {
    font-size: 13px;
    line-height: 1.5;
  }
}

@define-mixin inputText {
  @mixin typeSecondary;
  /* Anything smaller than 16px will trigger a zoom on focus on iOS devices */
  font-size: 16px;
  line-height: 1.5;
}

@define-mixin paragraphText {
  @mixin typeSecondary;
  font-size: 18px;
  line-height: 1.5;

  @media (--smartphone) {
    font-size: 16px;
    line-height: 1.5;
  }
}

@define-mixin subheaderText {
  @mixin typePrimary;
  font-size: 18px;
  line-height: 1.5;

  @media (--smartphone) {
    font-size: 16px;
    line-height: 1.5;
  }
}

@define-mixin headerText {
  @mixin typePrimary;
  font-size: 25px;
  line-height: 100%;

  @media (--smartphone) {
    font-size: 20px;
    line-height: 1.5;
  }
}

@define-mixin titleText {
  @mixin typeTertiary;
  font-size: 32px;
  line-height: 1.2;

  @media (--smartphone) {
    font-size: 28px;
    line-height: 1.5;
  }
}

@define-mixin displayText {
  @mixin typeVanity;
  font-size: 40px;
  line-height: 1.2;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin warningText {
  @mixin typeTertiary;
  font-size: 14px;
  line-height: 1.5;
}

@define-mixin megaText {
  @mixin typeVanity;
  font-size: 54px;
  line-height: 1.2;

  @media (--smartphone) {
    font-size: 36px;
    line-height: 1.2;
  }
}

@define-mixin navigationText {
  @mixin text_primary;
  font-family: "Spezia Medium";
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @media (--small-desktop) {
    font-size: 14px;
  }
  &:hover {
    @mixin text_brand;
  }
}

@define-mixin subNavigationText {
  font-family: "Spezia Medium";
  font-weight: 600;
  font-size: 13px;
  line-height: 1.5;
}

@define-mixin badgingText {
  @mixin typeVanity;
  font-size: 12px;
  line-height: 1.5;
}

@define-mixin buttonText {
  font-family: "Spezia SemiMono Medium";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 1.3px;
}

@define-mixin vanityButtonText {
  @mixin typeTertiary;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1.5;
}

@define-mixin promoTermsText {
  @mixin typePrimary;
  @mixin metaTitleText;
}

@define-mixin sansNarrowLarge {
  @mixin typeSansNarrowBold;
  font-size: 58px;
  line-height: 1;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin headlinesPrimary {
  @mixin typePrimaryNew;
  font-size: 90px;
  line-height: 1;

  @media (--smartphone-and-tablet) {
    font-size: 50px;
  }
}

@define-mixin headlinesSecondary {
  @mixin typeSansNarrowBold;
  font-weight: 700;
  font-size: 90px;
  line-height: 1;

  @media (--smartphone-and-tablet) {
    font-size: 50px;
  }
}


@define-mixin headerTextPrimary {
  @mixin typeSecondaryNew;
  font-size: 12px;
  line-height: 16px;

  @media (--tablet-and-desktop) {
    font-size: 16px;
    line-height: 24px;
  }
}

@define-mixin sansNarrowSmall {
  @mixin typeSansNarrowBold;
  font-size: 32px;
  line-height: 1.2;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin sansMediumLarge {
  @mixin typePrimary;
  font-size: 58px;
  line-height: 1;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin sansMediumSmall {
  @mixin typePrimary;
  font-size: 32px;
  line-height: 1.2;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin sansDemiLarge {
  @mixin typeTertiary;
  font-size: 58px;
  line-height: 1;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin sansDemiSmall {
  @mixin typeTertiary;
  font-size: 32px;
  line-height: 1.2;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin sansBoldLarge {
  @mixin typeVanity;
  font-size: 58px;
  line-height: 1;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin sansBoldSmall {
  @mixin typeVanity;
  font-size: 32px;
  line-height: 1.2;

  @media (--smartphone-and-tablet) {
    font-size: 24px;
    line-height: 1.2;
  }
}

@define-mixin serifLightSmall {
  @mixin typeSerifLight;
  font-size: 3vw;
  line-height: 1;
  letter-spacing: -1px;

  @media (--smartphone) {
    font-size: 10vw;
    line-height: 1.1;
    letter-spacing: -1px;
  }
}

@define-mixin serifLightLarge {
  @mixin typeSerifLight;
  font-size: 58px;
  line-height: 1;
  letter-spacing: -1px;

  @media (--smartphone) {
    font-size: 10vw;
    line-height: 1.1;
    letter-spacing: -1px;
  }
}

@define-mixin serifBoldLarge {
  @mixin typeSerifBold;
  font-size: 58px;
  line-height: 1;
  letter-spacing: -1px;

  @media (--smartphone) {
    font-size: 10vw;
    line-height: 1.1;
    letter-spacing: -1px;
  }
}
